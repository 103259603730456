
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Montserrat, sans-serif, "Noto Sans TC";
}

html, body {
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

a {
  color: #fff;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

header {
  position: absolute;
  height: 150px;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  z-index: 5;
  
  div {
      position: absolute;
      text-align: center;
      width: 226px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 50px;
      color: #fff;
      font-weight: bold;
      z-index: 5;
  }
}

.loading {
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.25) 200%);
  background-blend-mode: multiply;
  background-image: url("https://images.unsplash.com/photo-1545128485-c400e7702796?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-position: center center;

  p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 75px;
      z-index: 10;
  }
}



main {
  .started {
      position: absolute;
      top: 50%;
      left: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 250px;
      transform: translate(-50%, -50%);
      opacity: 0;
      z-index: 11;
      cursor: pointer;

      button {
          width: 102px;
          height: 102px;
          background: linear-gradient(135deg, white, #fdfdfd2b);
          border-radius: 50%;
          border: 2px solid white;
          outline-color: transparent;
          transition: 0.2s;
          cursor: pointer;
          opacity: 0.8;

          &:hover {
              opacity: 1;
              cursor: pointer;
          }

          &:focus {
            border: 2px solid white;
            outline-color: transparent;
          }


          &:focus-visible {
            border: 2px solid white;
            outline-color: transparent;
          }


      }

      p {
          margin-top: 10px;
          color: #fff;
      }
  }
  
  .player {
      position: absolute;
      top: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      z-index: 5;
      background-color: rgba(0, 0, 0, 0.459);
      opacity: 0;
      visibility: hidden;
  
      .player-source {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); 
          width: 85vw;
          height: 85vh;
          z-index: 6;

      }
  
      .player-close {
          position: absolute;
          width: 50px;
          height: 50px;
          top: 20px;
          right: 20px;
          border: none;
          border-radius: 50%;
          cursor: pointer;
          transition: 0.2s;
          z-index: 7;
  
          &:hover {
              transform: scale(1.05);
          }
  
          &::after {
              content: "";
              position: absolute;
              width: 25px;
              height: 2px;
              background-color: #000;
              transform: translate(-50%, -50%) rotate(45deg);
          }
      
          &::before {
              content: "";
              position: absolute;
              width: 25px;
              height: 2px;
              background-color: #000;
              transform: translate(-50%, -50%) rotate(-45deg);
          }
      }
  }
}

.started-btn:focus {
border: 2px solid white;
outline-color: transparent;
}

.started-btn:focus-visible {
border: 2px solid white;
outline-color: transparent;
}


footer {
  // opacity: 1;
  width: 100%;
  text-align: center;
  min-height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #a4a6a6f0;
  p {
      // position: absolute;
      bottom: 0;
      margin: 25px;
      color: #fff;
      z-index: 2;
  }
  a {
    color: #FFF;
  }
  a:hover {
    color: #504690;
  }
}

.footer-item {
display: inline-flex;
}

.main-cards {
  
}

h1 {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
}

img {
  height: auto;
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
}

.btn {
  color: #ffffff;
  padding: 0.8rem;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 400;
  display: block;
  width: 100%;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: transparent;
}

.btn:hover {
  background-color: rgba(255, 255, 255, 0.12);
}

.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.cards_item {
  display: flex;
  padding: 1rem;
}

@media (min-width: 40rem) {
  .cards_item {
    width: 50%;
  }
}

@media (min-width: 56rem) {
  .cards_item {
  //   width: 33.3333%;
      width: 100%;
  }
}

.card {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card_content {
  padding: 1rem;
  background: linear-gradient(to bottom left, #EF8D9C 40%, #FFC39E 100%);
}

.card_title {
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin: 0px;
}

.card_text {
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 1.25rem;    
  font-weight: 400;
}
.made_by{
  font-weight: 400;
  font-size: 13px;
  margin-top: 35px;
  text-align: center;
}


@import url("https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-alpha.6/css/bootstrap.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro");
@import url("https://fonts.googleapis.com/css?family=Varela+Round");
@import url("https://pro.fontawesome.com/releases/v5.0.13/css/all.css");

@font-face {
 font-family: 'Telefon Black';
 src: url('https://raw.githubusercontent.com/arnabmunshi/fontface/master/TelefonBlack/Telefon-Black.woff2') format('woff2'), url('https://raw.githubusercontent.com/arnabmunshi/fontface/master/TelefonBlack/Telefon-Black.woff') format('woff');
 font-weight: 900;
 font-style: normal;
}
.page-event {
 background-color: #212121;
 padding-bottom: 0;
 font-family: "Varela Round", sans-serif;
 width: 85%;
}
.page-event .cover {
 background-color: #36353a;
//  height: 360px;
 background-image: url('https://goo.gl/NA3iQP');
 background-position: center center;
 background-size: cover;
}
.page-event .cover .heading {
 font-family: 'Telefon Black';
 font-size: 62px;
 padding: 24px 0 6px 48px;
 letter-spacing: 0.08em;
 text-transform: uppercase;
 text-align: center;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
 color: #77cce5;
 transition: font-size 0.3s;
   margin-top: 80px;
   
}
.page-event .cover .heading::first-letter {
 color: #a17ff1;
}
.page-event .upcoming-sec {
 padding: 5vh 0;
 border-bottom: 1px solid #ccc;
}
.page-event .upcoming-sec .heading {
 font-size: 30px;
 color: #fff;
}
.page-event .upcoming-event-list {
 color: #ccc;
}
.page-event .upcoming-event-list .event-block {
 padding: 5vh 0;
 border-bottom: 1px solid #5b5b5b82;
}
.page-event .upcoming-event-list .event-block .sec-1, .page-event .upcoming-event-list .event-block .sec-2 {
 padding-bottom: 3vh;
}
.page-event .upcoming-event-list .event-block .sec-1 table .month {
 text-transform: uppercase;
 color: #db2d8e;
 font-size: 30px;
}
.page-event .upcoming-event-list .event-block .sec-1 table .month-date-devider {
 width: 5vh;
 margin: 1vh 0;
 background-color: #3cbfaf;
 height: 5px;
}
.page-event .upcoming-event-list .event-block .sec-1 table .date {
 font-size: 60px;
 font-weight: bolder;
 line-height: normal;
}
.page-event .upcoming-event-list .event-block .sec-1 table .title {
 padding-left: 20px;
 vertical-align: top;
 font-size: 30px;
}
.page-event .upcoming-event-list .event-block .sec-2 img {
 width: 100%;
}
.page-event .upcoming-event-list .event-block .sec-3 .title {
 font-size: 30px;
 padding-bottom: 2vh;
}
.page-event .upcoming-event-list .event-block .sec-3 .venue table td {
 vertical-align: top;
}
.page-event .upcoming-event-list .event-block .sec-3 .venue table td i {
 color: #db2d8e;
 padding-left: 3px;
 padding-right: 10px;
}
.page-event .upcoming-event-list .event-block .sec-3 .venue table td .dim-color a {
 color: rgba(204,204,204,0.3);
}
.page-event .upcoming-event-list .event-block .sec-3 .time {
 padding-bottom: 3vh;
}
.page-event .upcoming-event-list .event-block .sec-3 .time table td {
 vertical-align: top;
}
.page-event .upcoming-event-list .event-block .sec-3 .time table td i {
 color: #db2d8e;
 padding-right: 10px;
}
.page-event .upcoming-event-list .event-block .sec-3 .time table td .dim-color {
 color: rgba(204,204,204,0.3);
}
.page-event .upcoming-event-list .event-block .sec-3 .sort-story {
 -webkit-box-orient: vertical;
 display: -webkit-box;
 overflow: hidden;
}
.page-event .upcoming-event-list .event-block .sec-3 .group-of-btn {
 padding-top: 3vh;
}
.page-event .upcoming-event-list .event-block .sec-3 .group-of-btn .book-ticket {
 background-color: #3cbfaf;
 color: #fff;
 margin-bottom: 10px;
}
.page-event .page-heading {
 text-transform: uppercase;
 color: #fff;
 padding-bottom: 17px;
 word-break: break-all;
 padding: 10px;
}
.page-event .page-heading::first-letter {
 color: #db2d8e;
}
@media (min-width: 992px) {
 .upcoming-event-list .event-block .sec-1 table .title {
   display: none;
}
}
@media (max-width: 991px) {
 .page-event .cover .heading {
   font-size: 180px;
   padding-top: 7vh;
}
 .upcoming-event-list .event-block .sec-3 .title {
   display: none;
}
}
@media (max-width: 767px) {
 .page-event .cover .heading {
   font-size: 140px;
   padding-top: 11vh;
}
}
@media (max-width: 575px) {
 .page-event .cover {
   height: 214px;
}
 .page-event .cover .heading {
   font-size: 24px;
   padding: 28px 0 12px 0;
}
 .page-event .upcoming-event-list .event-block .sec-1 table .month {
   font-size: 14px;
}
 .page-event .upcoming-event-list .event-block .sec-1 table .date {
   font-size: 36px;
}
 .page-event .upcoming-event-list .event-block .sec-1 table .title {
   padding-left: 20px;
   font-size: 20px;
   vertical-align: top;
}
}


.logo-image {
  width: 100% !important;
  padding: 12px 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #36363a;
}

.logo-image:not(:first-child) {
padding-bottom: 64px;
}

.logo-image img {
  display: inline-flex;
}

// event page layout sass


$orange: #ff9f43;

// Default element styles
p {
line-height: 1.8em;
}
a {
color: #333;
}
p a {
position: relative;
text-decoration: none;
&:after {
  content: '';
  display: block;
  position: absolute;
  background: rgba($orange, .5);
  bottom: -5px;
  left: -3px;
  right: -3px;
  height: 5px;
}
}

// Styling the layout
.container {
max-width: 1080px;
margin: 0 auto;
display: flex;
}
.header-image {
position: absolute;
top: 0;
left: 0;
height: 600px;
width: 100%;
z-index: -1;
background: url('https://images.unsplash.com/photo-1554181143-8fc0399a0564?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') no-repeat center center;
background-size: cover;
background-attachment: fixed;
// clip-path: polygon(0 0, 100% 0, 100% 500px, 0 600px);
overflow: hidden;
// &:before {
//   content: '';
//   position: fixed; // instead of background-attachment
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   background-color: white;
//   will-change: transform; // creates a new paint layer
//   z-index: -1;
// }
&:after {
  // z-index: 1;
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(black, 0), rgba(black, .6));
}
}
.event-page {
width: 90%;
margin-top: 100px;
&__nav {
  display: block;
  list-style-type: none;
  position: fixed;
  left: 1.7rem;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  width: 50px;
  li a {
    display: block;
    width: 50px;
    height: 50px;
    background: white;
    margin: 5px 0;
    border-radius: 50%;
    box-shadow: 0 2px 6px rgba(black, .16);
    text-align: center;
    line-height: 50px;
    font-size: 24px;
  }
}
&__title {
  font-size: 2em;
  font-weight: 500;
  padding: 0 0 24px 0;
  color: white;
  text-align: center;
}
&__content {
  box-shadow: 0 2px 6px rgba(black, .16);
  section {
    padding: 40px;
  //   background: linear-gradient(304deg, #99aa85 0%, #8da0b1 25%, #abbeca 46%, #b1b0c4f0 67%, #a9acb2d1 100%);
  background: radial-gradient(343px at 46.3% 47.5%, rgb(242, 242, 242) 0%, rgb(241, 241, 241) 72.9%);
    &:nth-child(2n) {
      color: rgb(222, 222, 222);
      background: linear-gradient(272deg, #463858 0%, #4a6773b3 25%, #5c6e3a 46%, #60633c 67%, #5580b3 100%);
    }
  }
  h2 {
    margin-top: 0;
    font-weight: 600;
    line-height: 1em;
    position: relative;
    display: inline-block;
    margin-left: 10px;
    z-index: 1;
    
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      bottom: -.1em;
      left: -5px;
      width: calc(100% + 30px);
      height: .4em;
      background: $orange;
      z-index: -1;
      transform: skewX(20deg)
    }
  }
}
}

.web-title {
font-size: 28px;
color: #fff7b8;
}
.web-title:focus, .web-title:hover {
cursor: pointer;
color: #b5ff7c;
}

.loader {
--color: white;
--size-mid: 6vmin;
--size-dot: 1.5vmin;
--size-bar: 0.4vmin;
--size-square: 3vmin;

display: block;
position: relative;
width: 50%;
display: grid;
place-items: center;
}

.loader::before,
.loader::after {
content: '';
box-sizing: border-box;
position: absolute;
}


/**
loader --2
**/
.loader.--2::before,
.loader.--2::after {
width: var(--size-dot);
height: var(--size-dot);
background-color: var(--color);
border-radius: 50%;
opacity: 0;
animation: loader-2 2s cubic-bezier(0.2, 0.32, 0, 0.87) infinite;
}

.loader.--2::after {
animation-delay: 0.3s;
}

@keyframes loader-2 {
0%, 80%, 100% {
  opacity: 0;
}

33% {
  opacity: 1;
}

0%, 100% {
  transform: translateX(-4vmin);
}

90% {
  transform: translateX(4vmin);
}
}


@media (max-width: 575px) {

.swipe-section {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.main-webgl {
    
}

.hidden {
    opacity: 0;
    visibility: hidden;
}

.openList {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 1;
    transition: opacity 0.3s ease;
}

}

.image-description {
  color: white;
  text-align: left;
  padding: 0 60px 60px 60px;
  font-size: 20px;
  background-color: #36363a;
}

.player-source {
height: 260px; /* 手機裝置固定高度 */
min-height: 260px;
max-height: 260px;
overflow: hidden;
}

@media (min-width: 769px) {
.player-source {
  height: 700px; /* 非手機裝置固定高度 */
  min-height: 700px;
  max-height: 700px;
}
}