@import "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-alpha.6/css/bootstrap.min.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";
@import "https://fonts.googleapis.com/css?family=Source+Sans+Pro";
@import "https://fonts.googleapis.com/css?family=Varela+Round";
@import "https://pro.fontawesome.com/releases/v5.0.13/css/all.css";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif, Noto Sans TC;
}

html, body {
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
}

.webgl {
  outline: none;
  position: fixed;
  top: 0;
  left: 0;
}

header {
  z-index: 5;
  height: 150px;
  position: absolute;
  top: 65%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

header div {
  text-align: center;
  color: #fff;
  z-index: 5;
  width: 226px;
  font-size: 50px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading {
  background-blend-mode: multiply;
  background-color: #0000;
  background-image: url("https://images.unsplash.com/photo-1545128485-c400e7702796?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-position: center;
  background-repeat: repeat, repeat;
  background-size: auto, auto;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
}

.loading p {
  color: #fff;
  z-index: 10;
  font-size: 75px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

main .started {
  opacity: 0;
  z-index: 11;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

main .started button {
  cursor: pointer;
  opacity: .8;
  background: linear-gradient(135deg, #fff, #fdfdfd2b);
  border: 2px solid #fff;
  border-radius: 50%;
  outline-color: #0000;
  width: 102px;
  height: 102px;
  transition: all .2s;
}

main .started button:hover {
  opacity: 1;
  cursor: pointer;
}

main .started button:focus, main .started button:focus-visible {
  border: 2px solid #fff;
  outline-color: #0000;
}

main .started p {
  color: #fff;
  margin-top: 10px;
}

main .player {
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  background-color: #00000075;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
}

main .player .player-source {
  z-index: 6;
  width: 85vw;
  height: 85vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

main .player .player-close {
  cursor: pointer;
  z-index: 7;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  transition: all .2s;
  position: absolute;
  top: 20px;
  right: 20px;
}

main .player .player-close:hover {
  transform: scale(1.05);
}

main .player .player-close:after {
  content: "";
  background-color: #000;
  width: 25px;
  height: 2px;
  position: absolute;
  transform: translate(-50%, -50%)rotate(45deg);
}

main .player .player-close:before {
  content: "";
  background-color: #000;
  width: 25px;
  height: 2px;
  position: absolute;
  transform: translate(-50%, -50%)rotate(-45deg);
}

.started-btn:focus, .started-btn:focus-visible {
  border: 2px solid #fff;
  outline-color: #0000;
}

footer {
  text-align: center;
  background-color: #a4a6a6f0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 180px;
  display: flex;
}

footer p {
  color: #fff;
  z-index: 2;
  margin: 25px;
  bottom: 0;
}

footer a {
  color: #fff;
}

footer a:hover {
  color: #504690;
}

.footer-item {
  display: inline-flex;
}

h1 {
  text-align: center;
  font-size: 24px;
  font-weight: 400;
}

img {
  vertical-align: middle;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.btn {
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  background: none;
  border: 1px solid #fff3;
  border-radius: 4px;
  width: 100%;
  padding: .8rem;
  font-size: 14px;
  font-weight: 400;
  display: block;
}

.btn:hover {
  background-color: #ffffff1f;
}

.cards {
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.cards_item {
  padding: 1rem;
  display: flex;
}

@media (width >= 40rem) {
  .cards_item {
    width: 50%;
  }
}

@media (width >= 56rem) {
  .cards_item {
    width: 100%;
  }
}

.card {
  background-color: #fff;
  border-radius: .25rem;
  flex-direction: column;
  display: flex;
  overflow: hidden;
  box-shadow: 0 20px 40px -14px #00000040;
}

.card_content {
  background: linear-gradient(to bottom left, #ef8d9c 40%, #ffc39e 100%);
  padding: 1rem;
}

.card_title {
  color: #fff;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin: 0;
  font-size: 1.1rem;
  font-weight: 700;
}

.card_text {
  color: #fff;
  margin-bottom: 1.25rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
}

.made_by {
  text-align: center;
  margin-top: 35px;
  font-size: 13px;
  font-weight: 400;
}

@font-face {
  font-family: Telefon Black;
  src: url("https://raw.githubusercontent.com/arnabmunshi/fontface/master/TelefonBlack/Telefon-Black.woff2") format("woff2"), url("https://raw.githubusercontent.com/arnabmunshi/fontface/master/TelefonBlack/Telefon-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

.page-event {
  background-color: #212121;
  width: 85%;
  padding-bottom: 0;
  font-family: Varela Round, sans-serif;
}

.page-event .cover {
  background-color: #36353a;
  background-image: url("https://goo.gl/NA3iQP");
  background-position: center;
  background-size: cover;
}

.page-event .cover .heading {
  letter-spacing: .08em;
  text-transform: uppercase;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #77cce5;
  margin-top: 80px;
  padding: 24px 0 6px 48px;
  font-family: Telefon Black;
  font-size: 62px;
  transition: font-size .3s;
}

.page-event .cover .heading:first-letter {
  color: #a17ff1;
}

.page-event .upcoming-sec {
  border-bottom: 1px solid #ccc;
  padding: 5vh 0;
}

.page-event .upcoming-sec .heading {
  color: #fff;
  font-size: 30px;
}

.page-event .upcoming-event-list {
  color: #ccc;
}

.page-event .upcoming-event-list .event-block {
  border-bottom: 1px solid #5b5b5b82;
  padding: 5vh 0;
}

.page-event .upcoming-event-list .event-block .sec-1, .page-event .upcoming-event-list .event-block .sec-2 {
  padding-bottom: 3vh;
}

.page-event .upcoming-event-list .event-block .sec-1 table .month {
  text-transform: uppercase;
  color: #db2d8e;
  font-size: 30px;
}

.page-event .upcoming-event-list .event-block .sec-1 table .month-date-devider {
  background-color: #3cbfaf;
  width: 5vh;
  height: 5px;
  margin: 1vh 0;
}

.page-event .upcoming-event-list .event-block .sec-1 table .date {
  font-size: 60px;
  font-weight: bolder;
  line-height: normal;
}

.page-event .upcoming-event-list .event-block .sec-1 table .title {
  vertical-align: top;
  padding-left: 20px;
  font-size: 30px;
}

.page-event .upcoming-event-list .event-block .sec-2 img {
  width: 100%;
}

.page-event .upcoming-event-list .event-block .sec-3 .title {
  padding-bottom: 2vh;
  font-size: 30px;
}

.page-event .upcoming-event-list .event-block .sec-3 .venue table td {
  vertical-align: top;
}

.page-event .upcoming-event-list .event-block .sec-3 .venue table td i {
  color: #db2d8e;
  padding-left: 3px;
  padding-right: 10px;
}

.page-event .upcoming-event-list .event-block .sec-3 .venue table td .dim-color a {
  color: #cccccc4d;
}

.page-event .upcoming-event-list .event-block .sec-3 .time {
  padding-bottom: 3vh;
}

.page-event .upcoming-event-list .event-block .sec-3 .time table td {
  vertical-align: top;
}

.page-event .upcoming-event-list .event-block .sec-3 .time table td i {
  color: #db2d8e;
  padding-right: 10px;
}

.page-event .upcoming-event-list .event-block .sec-3 .time table td .dim-color {
  color: #cccccc4d;
}

.page-event .upcoming-event-list .event-block .sec-3 .sort-story {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.page-event .upcoming-event-list .event-block .sec-3 .group-of-btn {
  padding-top: 3vh;
}

.page-event .upcoming-event-list .event-block .sec-3 .group-of-btn .book-ticket {
  color: #fff;
  background-color: #3cbfaf;
  margin-bottom: 10px;
}

.page-event .page-heading {
  text-transform: uppercase;
  color: #fff;
  word-break: break-all;
  padding: 10px;
}

.page-event .page-heading:first-letter {
  color: #db2d8e;
}

@media (width >= 992px) {
  .upcoming-event-list .event-block .sec-1 table .title {
    display: none;
  }
}

@media (width <= 991px) {
  .page-event .cover .heading {
    padding-top: 7vh;
    font-size: 180px;
  }

  .upcoming-event-list .event-block .sec-3 .title {
    display: none;
  }
}

@media (width <= 767px) {
  .page-event .cover .heading {
    padding-top: 11vh;
    font-size: 140px;
  }
}

@media (width <= 575px) {
  .page-event .cover {
    height: 214px;
  }

  .page-event .cover .heading {
    padding: 28px 0 12px;
    font-size: 24px;
  }

  .page-event .upcoming-event-list .event-block .sec-1 table .month {
    font-size: 14px;
  }

  .page-event .upcoming-event-list .event-block .sec-1 table .date {
    font-size: 36px;
  }

  .page-event .upcoming-event-list .event-block .sec-1 table .title {
    vertical-align: top;
    padding-left: 20px;
    font-size: 20px;
  }
}

.logo-image {
  background-color: #36363a;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 12px 0;
  display: flex;
  width: 100% !important;
}

.logo-image:not(:first-child) {
  padding-bottom: 64px;
}

.logo-image img {
  display: inline-flex;
}

p {
  line-height: 1.8em;
}

a {
  color: #333;
}

p a {
  text-decoration: none;
  position: relative;
}

p a:after {
  content: "";
  background: #ff9f4380;
  height: 5px;
  display: block;
  position: absolute;
  bottom: -5px;
  left: -3px;
  right: -3px;
}

.container {
  max-width: 1080px;
  margin: 0 auto;
  display: flex;
}

.header-image {
  z-index: -1;
  background: url("https://images.unsplash.com/photo-1554181143-8fc0399a0564?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D") center / cover no-repeat fixed;
  width: 100%;
  height: 600px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.header-image:after {
  content: "";
  background: linear-gradient(#0000, #0009);
  display: block;
  position: fixed;
  inset: 0;
}

.event-page {
  width: 90%;
  margin-top: 100px;
}

.event-page__nav {
  width: 50px;
  padding: 0;
  list-style-type: none;
  display: block;
  position: fixed;
  top: 50%;
  left: 1.7rem;
  transform: translateY(-50%);
}

.event-page__nav li a {
  text-align: center;
  background: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 5px 0;
  font-size: 24px;
  line-height: 50px;
  display: block;
  box-shadow: 0 2px 6px #00000029;
}

.event-page__title {
  color: #fff;
  text-align: center;
  padding: 0 0 24px;
  font-size: 2em;
  font-weight: 500;
}

.event-page__content {
  box-shadow: 0 2px 6px #00000029;
}

.event-page__content section {
  background: radial-gradient(343px at 46.3% 47.5%, #f2f2f2 0%, #f1f1f1 72.9%);
  padding: 40px;
}

.event-page__content section:nth-child(2n) {
  color: #dedede;
  background: linear-gradient(272deg, #463858 0%, #4a6773b3 25%, #5c6e3a 46%, #60633c 67%, #5580b3 100%);
}

.event-page__content h2 {
  z-index: 1;
  margin-top: 0;
  margin-left: 10px;
  font-weight: 600;
  line-height: 1em;
  display: inline-block;
  position: relative;
}

.event-page__content h2:before {
  content: "";
  z-index: -1;
  background: #ff9f43;
  width: calc(100% + 30px);
  height: .4em;
  display: inline-block;
  position: absolute;
  bottom: -.1em;
  left: -5px;
  transform: skewX(20deg);
}

.web-title {
  color: #fff7b8;
  font-size: 28px;
}

.web-title:focus, .web-title:hover {
  cursor: pointer;
  color: #b5ff7c;
}

.loader {
  --color: white;
  --size-mid: 6vmin;
  --size-dot: 1.5vmin;
  --size-bar: .4vmin;
  --size-square: 3vmin;
  place-items: center;
  width: 50%;
  display: grid;
  position: relative;
}

.loader:before, .loader:after {
  content: "";
  box-sizing: border-box;
  position: absolute;
}

.loader.--2:before, .loader.--2:after {
  width: var(--size-dot);
  height: var(--size-dot);
  background-color: var(--color);
  opacity: 0;
  border-radius: 50%;
  animation: 2s cubic-bezier(.2, .32, 0, .87) infinite loader-2;
}

.loader.--2:after {
  animation-delay: .3s;
}

@keyframes loader-2 {
  0%, 80%, 100% {
    opacity: 0;
  }

  33% {
    opacity: 1;
  }

  0%, 100% {
    transform: translateX(-4vmin);
  }

  90% {
    transform: translateX(4vmin);
  }
}

@media (width <= 575px) {
  .swipe-section {
    opacity: 1;
    transition: opacity .3s;
  }

  .hidden {
    opacity: 0;
    visibility: hidden;
  }

  .openList {
    z-index: 1;
    opacity: 1;
    transition: opacity .3s;
    position: fixed;
    top: 0;
    left: 0;
  }
}

.image-description {
  color: #fff;
  text-align: left;
  background-color: #36363a;
  padding: 0 60px 60px;
  font-size: 20px;
}

.player-source {
  height: 260px;
  min-height: 260px;
  max-height: 260px;
  overflow: hidden;
}

@media (width >= 769px) {
  .player-source {
    height: 700px;
    min-height: 700px;
    max-height: 700px;
  }
}
/*# sourceMappingURL=index.fd34bf8a.css.map */
